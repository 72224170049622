// Colors
$charcoal-grey: #353c43;
$pale-gray: #f7f8f9;
$white: #fff;

$headings-color: $charcoal-grey;

// Font size
$font-size-root: 16px;
$font-size-base: 1rem; // 16px
$font-size-xs: 0.75rem; // 12px
$font-size-sm: 0.875rem; // 14px
$font-size-lg: 1.125rem; // 18px
$h1-font-size: 1.625rem; // 26px
$h2-font-size: 1.375rem; // 22px
$h3-font-size: 1.125rem; // 18px
$h4-font-size: 1rem; // 16px
$h5-font-size: 0.875rem; // 16px

// Font height
$h1-line-height: 2rem; // 32px
$h2-line-height: 1.75rem; // 18px
$h3-line-height: 1.5rem; // 24px
$h4-line-height: 1.5rem; // 24px
$h4-line-height: 1.5rem; // 24px
$h5-line-height: 1.25rem; // 20px

// Font weight
$font-weight-normal: 400;
$font-weight-bold: 700;
$headings-font-weight: $font-weight-bold;

// font-family
$font-family-sans-serif: 'HarmoniaSansStd', sans-serif;
$font-family-serif: Georgia, Times, serif;

// Spacing

$space-1: 0.5rem;
$space-2: 1rem;
$space-3: 2rem;
$space-4: 3rem;
$space-5: 4rem;
$space-6: 5rem;
