.bg-gray {
  background-color: $pale-gray;
}

.bg-white {
  background-color: $white;
}

.blue-text {
  color: #3f51b5;
}

.text-gray {
  color: #6e7072;
}

.text-white {
  color: $white;
}
