// *************
// Typography Styles
// *************

html {
  font-size: $font-size-root;
  font-family: $font-family-sans-serif !important;
  color: $charcoal-grey;
}

h1,
.h1 {
  font-size: $h1-font-size;
  margin-bottom: $h1-font-size;
  line-height: $h1-line-height;
  font-weight: $font-weight-bold;
}

h2,
.h2 {
  font-size: $h2-font-size;
  margin-bottom: $h2-font-size;
  line-height: $h2-line-height;
  font-weight: $font-weight-bold;
}

h3,
.h3 {
  font-size: $h3-font-size;
  margin-bottom: $h3-font-size;
  line-height: $h3-line-height;
  font-weight: $font-weight-bold;
}

h4,
.h4 {
  font-size: $h4-font-size;
  margin-bottom: $h4-font-size;
  line-height: $h4-line-height;
  font-weight: $font-weight-bold;
}

h5,
.h5 {
  font-size: $h5-font-size;
  margin-bottom: $h5-font-size;
  line-height: $h5-line-height;
  font-weight: $font-weight-bold;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  margin-top: 0;
  letter-spacing: 0;
  color: $headings-color;
}

p {
  font-family: $font-family-sans-serif !important;
}

.sm {
  font-size: $font-size-sm;
}

.xs {
  font-size: $font-size-xs;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-bold {
  font-weight: $font-weight-bold;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}
