a {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}

button {
  font-family: $font-family-sans-serif !important;
}

.border-bottom {
  border-bottom: 1px solid #ccced0;
}

.border-top {
  border-top: 1px solid #ecedee;
}

.border {
  border: 1px solid #ccced0;
}

.normal-text {
  color: $charcoal-grey;
  text-decoration: none;
}

.big-class-container {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 4px 16px 2px;
  background-color: green;
  color: $white;
}

.medium-class-container {
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 16px 1px;
  background-color: green;
  color: $white;
}

.small-class-container {
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 8px 1px;
  background-color: green;
  color: $white;
  margin-left: 8px;
}

.editor_container {
  border: 1px solid #f1f1f1;
  padding: 8px;
  max-height: 50vh;
  overflow: auto;
}

.MuiPopover-paper {
  min-width: calc(20% + 8px) !important;
}

.MuiChip-label {
  font-family: $font-family-sans-serif !important;
}

.MuiInputBase-input,
.MuiListItem-root {
  font-family: $font-family-sans-serif !important;
}

.cursor-pointer {
  cursor: pointer;
}

.openseadragon-container {
  display: none;

  &:first-child {
    display: block !important;
  }
}

div {
  &:focus {
    outline: 0;
  }
}

.delete-class {
  margin-left: 8px !important;
  margin-bottom: 2px !important;
}

.img-fluid {
  max-width: 100%;
}

.review-container {
  .editor-class {
    min-height: 440px;
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding-bottom: 2rem;
  background-color: #fff;
}

.MuiTabs-scroller  {
  overflow: visible !important;
}

.ellipsis-text {
  height: 44px;
  overflow: hidden;
  transition: height ease-in-out 300ms;
}

// language switchers
.lang-btn {
  border: 1px solid rgb(191, 191, 191);
  border-radius: 0;
  padding: 3px;
  line-height: 8px;
  background-color: white;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  &.active {
    cursor: default;
    border: 2px solid black;
    &:hover {
      box-shadow: none;
    }
  }
}
.lang-img {
  display: block;
  width: 30px;
}
